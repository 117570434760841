* {
  outline: none;
}
#root{
  height: 100%;
}

.ck-editor__editable {max-height: 400px;}
.notification-container {
  z-index: 3939393939939 !important;
}
.dashboard-container{
  height: auto;
}

.color-red{
  color: red;
}

.yellow-text{
  color: rgb(228, 219, 104);
}
.brown-pool{
  background-color: #a0830b;
}

.purple-pool{
  background-color: purple;
}

.purple-text{
  color: purple;
}

.border-red{
  border-color: red;
}
.css-1pahdxg-control, .css-yk16xz-control{
  height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
}
.btn-disabled{
  border-color: gray;
  background-color: gray;
  cursor: not-allowed;
  pointer-events: none;
}


.align-items-center{
  align-items: center;
}

.justify-contents-center{
  justify-content: center;
}

.justify-contents-space-evenly{
  justify-content: space-evenly !important;
}

.text-center{
  text-align: center;
}
.text-right{
  text-align: right;
}
.transaction-btn{
  padding: 10px;
  line-height: 13px;
}

.flex-direction-column{
  flex-direction: column;
}

.flex-direction-row{
  flex-direction: row !important;
}

.extended-width{
  width:20% !important;
}


@media screen and (max-width:500px) {
  .extended-width.extend{
    width:40% !important;
  }
}

.filter-input-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}


.nav-block{
  position: relative;
}
.nav-title{
  opacity: 0;
  display: none;
  text-transform: uppercase;
}
.nav-block:hover .nav-title{
  animation: fadeInFromNone 600ms ease-in-out 0s forwards;
  background: #a0830b;
  color: white;
  width: 180px;
  height: 48.22px;
  left: 50px;
  position: absolute;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 0px; */
  font-family: Futuralt, sans-serif;
  font-size: 14px;
}

.red{
  background: red;
  border-color: red;
}
.red-pool{
  background-color: red;
}
.red-text{
  color: red;
}

.bypass-btn{
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.bypass-btn:hover{
  color:#a0830b;
}
@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}

.w-50{
  width: 50% !important;
  float: left;
}

.overflow-scroll{
  overflow: scroll;
}
.mic{
  width: 30%;
}
.w-120{
  width: 120% !important;
}

.dashboard-nav-link.admin-icon {
  background-image: url('/assets/images/admin-icon-black.svg') !important;
  background-size: 50%;
}

.dashboard-link-hover-state.admin-icon {
  background-image: url('/assets/images/admin-icon-white.svg') !important;
  background-size: 50%;
}

.dashboard-nav-link.media-icon {
  background-image: url('/assets/images/media-black.png') !important;
  background-size: 50%;
}

.dashboard-link-hover-state.media-icon {
  background-image: url('/assets/images/media-black.png') !important;
  background-size: 50%;
}

.dashboard-nav-link.growth-icon {
  background-image: url('/assets/images/volition-growth-black.svg') !important;
  background-size: 50%;
}

.dashboard-link-hover-state.growth-icon {
  background-image: url('/assets/images/volition-growth-white.svg') !important;
  background-size: 50%;
}

.dashboard-nav-link.cms-icon {
  background-image: url('/assets/images/voliton-cms-icon-black.svg') !important;
  background-size: 50%;
}

.dashboard-link-hover-state.cms-icon {
  background-image: url('/assets/images/voliton-cms-icon-white.svg') !important;
  background-size: 50%;
}

.h-500{
  height: 500px !important;
}
.w-100{
  width: 100% !important;
}

.black-text{
  color: black !important;
}
.brown-text{
  color: #a0830b !important;
}
.gray-text{
  color: gray !important;
}
.silver-pool{
  background-color: silver;
}
.gray-pool{
  background-color:  gray;
}
.network-loader {
  position: fixed;
  top: 8px;
  left: 8px;

  border: 4px solid white; /* Light grey */
  border-top: 4px solid #a0830b; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  z-index: 5;
}
.html-preview-body-content{
  max-height: 40px;
    overflow: hidden;
}
/* .investment-breakdown-header{
  overflow: auto;
}
.content-block{
  min-width: 200px;
} */
/* .parent-table-container{
  overflow: auto;
} */
@media only screen and (min-width: 767px){
  .dashboard-inner-wrapper{
    position: fixed;
    margin-left: 120px;
    right: 0px;
    left: 0px;
  }

}
@media only screen and (max-width: 767px){
  .filter-input-container{
    flex-direction: column !important;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.faq-bordered{
  border: 2px solid #E3DBBB;
  box-shadow: 0px 0px 10px #E3DBBB;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;
}

.inner-faq-bordered{
  /* border: 2px solid #ebe7d6; */
  box-shadow: 0px 0px 10px #ebe7d6;
  padding: 10px 10px 10px 20px;
  margin-bottom: 5px;

  margin-left: 20px;
  margin-right: 20px;
}

.bg-beige{
  background: beige;
}


.close-button::before {
  display: inline-block;
  content: "x"; /* This will render the 'X' */
  font-size: 17px;
  cursor: pointer;
}

.close-button::hover{
  opacity: 0.8;
}

.ql-toolbar.ql-snow{
  background: #FAFAFA;
}

.ql-container *{
  font-family: Futuralt, sans-serif;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before, .ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.portfolio-managment-pool {
  word-break: break-all
}

@media screen and (max-width: 479px) {

  .nav-block:hover .nav-title{
    display: none;
  }

}