.loader-container{
  display: flex;
  align-self: center;
  justify-content: center;
  margin-top: 20px;
}
@keyframes ldio-mvk9yu39ej8 {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.ldio-mvk9yu39ej8 div {
  left: 98px;
  top: 78px;
  position: absolute;
  animation: ldio-mvk9yu39ej8 linear 1s infinite;
  background: #a0830b;
  width: 4px;
  height: 12px;
  border-radius: 1.32px / 1.32px;
  transform-origin: 2px 22px;
}.ldio-mvk9yu39ej8 div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9166666666666666s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -0.8333333333333334s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.75s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.6666666666666666s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.5833333333333334s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.5s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.4166666666666667s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.3333333333333333s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.25s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.16666666666666666s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.08333333333333333s;
  background: #a0830b;
}.ldio-mvk9yu39ej8 div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #a0830b;
}
.loadingio-spinner-spinner-fox165sqzi {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-mvk9yu39ej8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-mvk9yu39ej8 div { box-sizing: content-box; }
